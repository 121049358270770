/* MobileMenu.css */
.mobile-menu {
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 0;
    transform: translateY(-100%);
    display: none; /* Initially not displayed */
}

.mobile-menu.open {
    display: block; /* Displayed when open */
    opacity: 1;
    transform: translateY(0);
}

.mobile-menu.closed {
    display: none; /* Ensure it's not clickable when closed */
}
