@tailwind base;
@tailwind components;
@tailwind utilities;


/* Feed.module.css */

.feedContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
}

.post {
    padding: 20px;
    border: 1px solid #e1e1e1; /* Light grey border */
    border-radius: 8px;
    background-color: #fafafa; /* Very light grey background */
    color: #333; /* Dark grey text */
}

.loading {
    text-align: center;
    margin-top: 20px;
}
